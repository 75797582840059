import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';

import { TranslateService } from '@app/shared/services/translate.service';
import { loadMessages, locale } from 'devextreme/localization';

import { Router } from '@angular/router';
import enMessages from 'devextreme/localization/messages/en.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { AuthService } from './shared/services/auth.service';

import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { UserProfileService } from './shared/services/api/user-profile.service';
import { CheckAppVersionService } from './shared/services/build-details/check-app-version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    locale: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private translateService: TranslateService,
        private primeNGConfig: PrimeNGConfig,
        private broadcastService: MsalBroadcastService,
        private userProfileService: UserProfileService,
        private checkAppVersionService: CheckAppVersionService
    ) {
        this.checkAppVersionService.init();
        this.initLocalization();

        this.translateService.onLanguageChange().subscribe(() => {
            this.changeLocale(this.translateService.getCurrentLanguage());
        });

        this.translateService.getPrimeNgConfig().subscribe(res => {
            this.primeNGConfig.setTranslation(res);
        });
    }

    async ngOnInit() {
        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                this.authService.setUserDataAccess(result.payload);
                this.userProfileService.logUserAccess().subscribe();
                this.router.navigate(['/']);
            });
    }

    initLocalization() {
        this.locale = this.getLocale();
        loadMessages(enMessages);
        loadMessages(ptMessages);
        locale(this.locale);
    }

    getLocale() {
        var locale = sessionStorage.getItem('locale');
        return locale != null ? locale : 'pt';
    }

    changeLocale(lang) {
        sessionStorage.setItem('locale', lang);
        parent.document.location.reload();
    }
}
