import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { USER_URL } from '@app/core/constants/urls/user.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpRenewExpiredTokenInterceptor implements HttpInterceptor {
    constructor(
        private msalInstance: MsalService,
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authService.isLoggedIn) return next.handle(request);

        const currentDateUtc = this.getCurrentDateTimestampUtc();
        const expirationDateTokenUtc = this.authService.getTokenExpiresOn();

        if (expirationDateTokenUtc < currentDateUtc) {
            return this.acquireSilentToken(request, next);
        }
        return next.handle(request);
    }

    acquireSilentToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentAccount = this.msalInstance.instance.getAllAccounts()[0];
        let silentRequest = {
            scopes: ['user.read'],
            account: currentAccount,
            forceRefresh: false,
        };

        return this.msalInstance.acquireTokenSilent(silentRequest).pipe(
            mergeMap(r => {
                if (r instanceof InteractionRequiredAuthError) {
                    this.authService.logout();
                    throw new Error(`Unable to renew token: ${r.message}`);
                } else {
                    const userAuthData = this.authService.mapNewUserAuthDataFromResponse(r);
                    this.authService.setUserDataAccessLocalStorage(userAuthData);

                    this.updateUserProfile();

                    req = req.clone({
                        headers: new HttpHeaders({
                            Authorization: `Bearer ${this.authService.getToken() ?? ''}`,
                        }),
                    });
                    return next.handle(req);
                }
            })
        );
    }

    getCurrentDateTimestampUtc(): number {
        const currentDate = new Date();
        const currentTimestampUtc = Date.UTC(
            currentDate.getUTCFullYear(),
            currentDate.getUTCMonth(),
            currentDate.getUTCDate(),
            currentDate.getUTCHours(),
            currentDate.getUTCMinutes(),
            currentDate.getUTCSeconds()
        );
        return currentTimestampUtc;
    }

    private updateUserProfile(): void {
        this.httpClient.put(USER_URL.UPDATE_USER_PROFILE(), {}).subscribe();
    }
}
