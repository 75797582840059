import { Injectable, inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BuildDetailsModel } from '@app/shared/models/build-details.model';
import { Observable, catchError, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuildDetailsService {
    private http = inject(HttpClient);

    getBuildDetails(): Observable<BuildDetailsModel> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
            }),
        };

        return this.http
            .get<BuildDetailsModel>('assets/build-details.json', httpOptions)
            .pipe(
                catchError(err => {
                    throw err;
                })
            )
            .pipe(take(1));
    }
}
