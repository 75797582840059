import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

import { AuthService } from '@app/shared/services/auth.service';
import { LoginCallbackComponent } from './modules/account/login-callback/login-callback.component';
import { UserSettingsComponent } from './modules/account/user-settings/user-settings.component';
import { AuthenticatedLayoutComponent } from './modules/authenticated-layout/authenticated-layout.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { SystemManagementGuard } from './shared/guards/system-management.guard';

const homeModule = () => import('./modules/home/home.module').then(x => x.HomeModule);
const accountModule = () => import('./modules/account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./modules/admin/admin.module').then(x => x.AdminModule);
const maintenanceModule = () =>
    import('./modules/systematic-maintenance/maintenance.module').then(x => x.MaintenanceModule);
const portfolioModule = () =>
    import('./modules/portfolio/portfolio.module').then(x => x.PortfolioModule);
const shutdownManagementModule = () =>
    import('./modules/shutdown-management/shutdown-management.module').then(
        x => x.ShutdownManagementModule
    );
const operationalAssumptionsModule = () =>
    import('./modules/operational-assumptions/operational-assumptions.module').then(
        m => m.OperationalAssumptionsModule
    );
const mapAdherenceModule = () =>
    import('./modules/map-adherence/map-adherence.module').then(m => m.MapAdherenceModule);
const userModule = () => import('./modules/user/user.module').then(m => m.UserModule);
const portfolioProcessModule = () =>
    import('./modules/portfolio-process/portfolio-process.module').then(
        m => m.PortfolioProcessModule
    );
const workCenterCapacityModule = () =>
    import('./modules/work-center-capacity/work-center-capacity.module').then(
        m => m.WorkCenterCapacityModule
    );
const componentsLifecycleModule = () =>
    import('./modules/components-lifecycle/components-lifecycle.module').then(
        m => m.ComponentsLifecycleModule
    );
const notificationModule = () =>
    import('./modules/notification/notification.module').then(m => m.NotificationModule);
const projectDemandModule = () =>
    import('./modules/project-demand/project-demand.module').then(m => m.ProjectDemandModule);
const workflowModule = () =>
    import('./modules/workflow/workflow.module').then(m => m.WorkflowModule);
const workflowConfigModule = () =>
    import('./modules/workflow-config/workflow-config.module').then(m => m.WorkflowConfigModule);
const functionalLocationGroupModule = () =>
    import('./modules/functional-location-group/functional-location-group.module').then(
        m => m.FunctionalLocationGroupModule
    );
const automaticEmailModule = () =>
    import('./modules/automatic-email/automatic-email.module').then(m => m.AutomaticEmailModule);
const faqModule = () => import('./modules/faq/faq.module').then(m => m.FaqModule);
const noticeModule = () => import('./modules/notice/notice.module').then(m => m.NoticeModule);
const reportsModule = () => import('./modules/reports/reports.module').then(m => m.ReportsModule);
const maintenanceStrategyModule = () =>
    import('./modules/maintenance-strategy/maintenance-strategy.module').then(
        m => m.MaintenanceStrategyModule
    );
const emailActionModule = () =>
    import('./modules/email-action/email-action.module').then(x => x.EmailActionModule);
const hourmeterModule = () =>
    import('./modules/hourmeter/hourmeter.module').then(m => m.HourmeterModule);

const routes: Routes = [
    {
        path: '',
        component: AuthenticatedLayoutComponent,
        canActivate: mapToCanActivate([AuthGuard]),
        children: [
            { path: '', loadChildren: homeModule },
            {
                path: 'admin',
                loadChildren: adminModule,
                canActivate: [SystemManagementGuard],
            },
            { path: 'error', component: ErrorComponent },
            // { path: 'maintenance', loadChildren: maintenanceModule },
            { path: 'portfolio', loadChildren: portfolioModule },
            {
                path: 'shutdown-management',
                loadChildren: shutdownManagementModule,
            },
            {
                path: 'operational-assumptions',
                loadChildren: operationalAssumptionsModule,
            },
            // { path: 'map-adherence', loadChildren: mapAdherenceModule }, // Disabled S57 US1117424
            { path: 'user', loadChildren: userModule },
            // { path: 'portfolio-process', loadChildren: portfolioProcessModule },
            {
                path: 'work-center-capacity',
                loadChildren: workCenterCapacityModule,
            },
            // {
            //     path: 'component-lifecycle',
            //     loadChildren: componentsLifecycleModule,
            // },
            { path: 'notification', loadChildren: notificationModule },
            { path: 'project-demand', loadChildren: projectDemandModule },
            {
                path: 'workflow',
                loadChildren: workflowModule,
            },
            {
                path: 'workflow-config',
                loadChildren: workflowConfigModule,
            },
            {
                path: 'functional-location-group',
                loadChildren: functionalLocationGroupModule,
            },
            {
                path: 'automatic-email',
                loadChildren: automaticEmailModule,
                canActivate: [SystemManagementGuard],
            },
            {
                path: 'faq',
                loadChildren: faqModule,
            },
            {
                path: 'notice',
                loadChildren: noticeModule,
            },
            {
                path: 'reports',
                loadChildren: reportsModule,
            },
            {
                path: 'maintenance-strategy',
                loadChildren: maintenanceStrategyModule,
            },
            {
                path: 'user-settings',
                component: UserSettingsComponent,
                data: {
                    breadcrumb: 'GLOBAL.Settings',
                },
            },
            {
                path: 'hourmeter',
                loadChildren: hourmeterModule,
            },
        ],
    },
    { path: 'email-action', loadChildren: emailActionModule },
    { path: 'account', loadChildren: accountModule },
    { path: 'login-callback', component: LoginCallbackComponent },
    { path: '**', pathMatch: 'full', redirectTo: '/' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    providers: [AuthService],
    exports: [RouterModule],
})
export class AppRoutingModule {}
