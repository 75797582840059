import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ROUTES } from '@app/core/constants/routes';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.authService.isLoggedIn) return true;

        this.authService.setReturnUrl(state.url);

        this.router.navigate([ROUTES.account.login]);

        return false;
    }
}
