import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteAccessLogService } from '@app/shared/services/api/route-access-log.service';
import { AuthService } from '@app/shared/services/auth.service';
import { RouteTranslationTagsService } from '@app/shared/services/routeTranslationTags.service';
import { ScreenSizeService } from '@app/shared/services/screen-size.service';
import { filter, Subscription } from 'rxjs';
import { NotificationSocketService } from '../notification/services/notification-socket.service';

@Component({
    selector: 'app-authenticated-layout',
    templateUrl: './authenticated-layout.component.html',
    styleUrl: './authenticated-layout.component.scss',
})
export class AuthenticatedLayoutComponent implements OnInit, OnDestroy {
    isMobile = false;
    observers = new Subscription();
    screenSizeService = inject(ScreenSizeService);

    notificationSocketService = inject(NotificationSocketService);
    routeTranslationTagsService = inject(RouteTranslationTagsService);
    router = inject(Router);
    authService = inject(AuthService);
    routeAccessLogService = inject(RouteAccessLogService);

    async ngOnInit(): Promise<void> {
        this.notificationSocketService.setup();
        this.setupLogAccessPage();

        this.observers = this.screenSizeService.isMobile$.subscribe(
            isMobile => (this.isMobile = isMobile)
        );
    }

    ngOnDestroy(): void {
        this.observers.unsubscribe();
    }

    private async setupLogAccessPage(): Promise<void> {
        const routesToLog = await this.routeTranslationTagsService.getRoutesTag();

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (routesToLog.routeExists(event.url)) {
                    const route = event.url;
                    const user = this.authService.getEmployeeId();

                    if (user) {
                        this.routeAccessLogService.add({ route, user }).subscribe();
                    }
                }
            });
    }
}
